import React from "react";
import { makeStyles } from "@material-ui/core";
import ContactForm from "../components/ContactForm";

import Layout from "../components/Layout/index";
import { Helmet } from "react-helmet";
import italyBg from "../images/italy-bg.jpg";

const useStyles = makeStyles(() => ({
  heading: {
    margin: "0 0 4rem 0",
    "& h2": {
      fontSize: "4vw",
    },
  },
}));

function MetaTags() {
  return (
    <Helmet>
      <title>{`Contact us- Pantano Music`}</title>
      <meta
        name="description"
        content="Contact us, follow us in social media or your favorite music service"
      />
      <meta property="og:title" content="Contact us - Pantano Music" />
      <meta property="og:description" content="Contact us" />
      <meta property="og:image" content={italyBg} />
      <meta property="og:url" content="https://www.pantanomusic.com/contact" />
      <meta name="twitter:card" content="summary_large_image" />
    </Helmet>
  );
}

export default function Contact() {
  const classes = useStyles();
  return (
    <Layout>
      <MetaTags />
      <h1>Contact Us</h1>
      <div className={classes.heading}>
        <a title="email" href="mailto:pantano@jspmultimedia.com">
          <h2>pantano@jspmultimedia.com</h2>
        </a>
      </div>
      <ContactForm />
    </Layout>
  );
}
